<template>
  <overlay
    :show="loading"
    class-name="content-wrapper"
  >
    <PageHeader
      :screen-name="'Editar '+ form.nome"
      :link-items="linkItems"
    />

    <ConfirmModal
      item-identifier="usuário"
      :modal-show="modal.showModal"
      :type-modal="modal.typeModal"
      @confirmed="redirectToList"
      @canceled="closeModalConfirm"
    />

    <ActionColaboradorModal
      item-identifier="usuário"
      :modal-show="modalAction.showModal"
      :type-modal="modalAction.typeModal"
      @confirmed="desvincularColaborador"
      @canceled="closeModalAction"
    />

    <SucessoModal
      :modal-show="modalSucesso.showModal"
      :type-modal="modalSucesso.typeModal"
      :case-modal="modalSucesso.caseModal"
      @confirmed="redirectToList"
    />

    <ErrorModal
      :modal-show="modalError.showModal"
      :type-modal="modalError.typeModal"
      :case-modal="modalError.caseModal"
      @confirmed="redirectToList"
    />

    <main class="card">
      <HeaderEmpresa
        :empresa="empresa"
        @confirmed="resolveRoute"
      />
      <section class="form p-2 mt-2">
        <validation-observer ref="colaboradorRules">
          <b-form>
            <b-row>
              <b-col
                lg="4"
                md="4"
                sm="12"
              >
                <b-form-group
                  label="CPF*"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="CPF"
                    rules="required|cpf"
                  >
                    <b-form-input
                      id="cpf"
                      v-model="form.cpf"
                      v-mask="'###.###.###-##'"
                      placeholder="000.000.000-00"
                      autocomplete="off"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                lg="4"
                md="4"
                sm="12"
              >
                <b-form-group label="Nome completo*">
                  <validation-provider
                    #default="{ errors }"
                    name="nome"
                    rules="required"
                  >
                    <b-form-input
                      id="nome-input"
                      v-model="form.nome"
                      :state="errors.length > 0 || form.errorApi.nome || form.errorApi.error ? false:null"
                      placeholder="John Doe"
                      :disabled="!editavel"
                    />
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >
                      Este campo é de preenchimento obrigatório.
                    </small>
                    <small
                      v-if="form.errorApi.nome"
                      class="text-danger"
                    >
                      {{ form.errorApi.nome }}
                    </small>
                    <small
                      v-if="form.errorApi.error"
                      class="text-danger"
                    >
                      {{ form.errorApi.error }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="4" md="4" sm="12">
                <b-form-group label="Nome da mãe">
                  <validation-provider #default="{ errors }" name="nome_mae">
                    <b-form-input
                      :state="
                        errors.length > 0 || form.errorApi.nome_mae
                          ? false
                          : null
                      "
                      id="nome_mae-input"
                      v-model="form.nome_mae"
                      :disabled="!editavel"
                      placeholder="Nome da mãe"
                    />
                    <small v-if="form.errorApi.nome_mae" class="text-danger">
                      {{ form.errorApi.nome_mae }}
                    </small>
                    <small v-if="form.errorApi.error" class="text-danger">
                      {{ form.errorApi.error }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                lg="4"
                md="4"
                sm="12"
              >
                <validation-provider
                  #default="{ errors }"
                  name="sexo"
                >
                  <b-form-group
                    label="Sexo*"
                    :state="errors.length > 0 ? false:null"
                  >
                    <v-select
                      id="sexo"
                      v-model="form.sexo"
                      :options="generoSelect"
                      label="topico"
                      placeholder="Selecione uma opção"
                      :clearable="true"
                    />
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >
                      Este campo é de preenchimento obrigatório.
                    </small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                lg="4"
                md="4"
                sm="12"
              >
                <b-form-group label="Data de nascimento*">
                  <validation-provider
                    #default="{ errors }"
                    name="data_nascimento"
                  >
                    <b-input-group>
                      <vue-cleave
                        id="data-nascimento-input"
                        v-model="input.data_nascimento"
                        :raw="false"
                        :options="option.data"
                        class="form-control"
                        placeholder="00/00/0000"
                        name="data_nascimento"
                        autocomplete="off"
                        :disabled="!editavel"
                        @input="setNascimentoInput"
                      />
                      <b-input-group-append>
                        <b-form-datepicker
                          v-model="form.data_nascimento"
                          locale="pt-BR"
                          button-only
                          right
                          :hide-header="true"
                          :max="today"
                          class="form-control d-flex align-items-center"
                          label-help="Selecione a data de nascimento"
                          button-variant="custom-blue"
                          clearable
                          :disabled="!editavel"
                          @input="setNascimentoPicker"
                        />
                      </b-input-group-append>
                    </b-input-group>

                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >
                      Este campo é de preenchimento obrigatório.
                    </small>
                    <small
                      v-if="form.errorApi.data_nascimento"
                      class="text-danger"
                    >
                      {{ form.errorApi.data_nascimento }}
                    </small>
                    <small
                      v-if="form.errorApi.error"
                      class="text-danger"
                    >
                      {{ form.errorApi.error }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                lg="4"
                md="4"
                sm="12"
              >
                <b-form-group label="E-mail*">
                  <validation-provider #default="{ errors }" name="E-mail" rules="required|email">
                    <b-form-input
                      id="email"
                      v-model="form.email"
                      placeholder="johndoe@gmail.com"
                      autocomplete="off"
                      type="email"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="4" md="4" sm="12">
                <NacionalidadeField
                  v-if="populated"
                  :idNacionalidade="form.id_nacionalidade"
                  @updateNacionalidade="updateNacionalidade"
                  :errorApiIdNacionalidade="form.errorApi.id_nacionalidade"
                  customClass="edit-company-user-colaborador"
                />
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </section>
      <section class="buttons p-2">
        <b-button
          id="save-user"
          class="mr-2 mb-2 mb-sm-0"
          variant="custom-blue"
          :disabled="saving"
          @click.prevent="validationForm(true)"
        >
          <feather-icon
            icon="CheckIcon"
            class="mr-50"
          />
          <span class="align-middle">Salvar usuário</span>
          <span
            v-if="saving"
            class="spinner-border spinner-border-sm ml-1"
            role="status"
            aria-hidden="true"
          />
        </b-button>
        <b-button
          id="cancel-register"
          :disabled="saving"
          variant="outline-danger"
          @click.prevent="openModalConfirm"
        >
          <feather-icon
            icon="XIcon"
            class="mr-50"
          />
          <span class="align-middle">Cancelar</span>
        </b-button>
      </section>
    </main>
  </overlay>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BFormDatepicker, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import moment from 'moment'
import VueCleave from 'vue-cleave-component'
import { getArrayAttr } from '@core/utils/utils'
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue'
import ActionColaboradorModal from '@/views/components/custom/modals/ActionsColaboradorModal.vue'
import ConfirmModal from '@/views/components/custom/modals/ConfirmModal.vue'
import SucessoModal from '@/views/components/custom/modals/SucessoCriacaoEdicaoModal.vue'
import ErrorModal from '@/views/components/custom/modals/ErroIdInexistenteModal.vue'
import HeaderEmpresa from '@/views/components/custom/page-header/Header-empresa.vue'
import { getCompanyUserId, updateCompanyUser } from '@/views/custom-pages/gerenciarUsuarios/requests'
import Overlay from '@/views/components/custom/overlay/Overlay.vue'
import { warningInfoMessage, warningMessage } from '@/libs/sweetalerts'
import NacionalidadeField from "@/views/components/custom/field-select-nacionalidade/NacionalidadeField.vue";

export default {
  title: 'Editar colaborador',
  components: {
    Overlay,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    PageHeader,
    ConfirmModal,
    SucessoModal,
    ActionColaboradorModal,
    BFormDatepicker,
    HeaderEmpresa,
    ErrorModal,
    BInputGroup,
    BInputGroupAppend,
    VueCleave,
    NacionalidadeField,
  },
  data() {
    return {
      loading: true,

      linkItems: [
        {
          name: 'Empresa',
          routeName: 'empresa-usuario-list',
        },
        {
          name: 'Carregando dados...',
          active: true,
        },
        {
          name: 'Editar colaborador',
          active: true,
        },
      ],
      modal: {
        showModal: false,
        typeModal: 'danger',
      },
      modalAction: {
        showModal: false,
        typeModal: 'warning',
      },
      modalSucesso: {
        showModal: false,
        typeModal: 'success',
        caseModal: 'edicao',
      },
      modalError: {
        showModal: false,
        typeModal: 'error',
        caseModal: 'idInexistente',
      },
      required,
      savedAlert: false,
      saving: false,
      savingAndNew: false,
      today: moment().format('YYYY-MM-DD'),
      option: {
        data: {
          date: true,
          delimiter: '/',
          datePattern: ['d', 'm', 'Y'],
        },
        cpf: {
          numericOnly: true,
          uppercase: true,
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 2],
        },
      },
      form: {
        id_usuario: '',
        id_pessoa_fisica: '',
        cpf: '',
        nome: '',
        sexo: '',
        data_nascimento: '',
        email: '',
        empresa: [],
        nome_mae: "",
        id_nacionalidade: null,
        errorApi: {
          cpf: '',
          nome: '',
          sexo: '',
          data_nascimento: '',
          email: '',
          error: '',
          nome_mae: "",
          id_nacionalidade: "",
        },
      },
      input: {
        data_nascimento: '',
      },
      generoSelect: [],
      categoriaSelect: [],
      empresa: {
        id_empresa: '',
        nome: '',
      },
      editavel: true,
      populated: false,
    }
  },
  mounted() {
    if (!this.$route.params.idUsuario) {
      this.openModalError()

      return
    }

    this.form.id_usuario = this.$route.params.idUsuario

    this.index()

    this.generoSelect = ['Masculino', 'Feminino']
  },
  methods: {
    async index() {
      this.loading = true

      await getCompanyUserId(this.$route.params.idUsuario).then(({ data }) => {
        this.preenchimentoFormulario(data)
      })

      this.loading = false
    },

    validationForm(redirectToList) {
      this.$refs.colaboradorRules.validate().then(success => {
        if (success) {
          this.editColaborador(redirectToList)
        }
      })
    },
    async editColaborador(redirectToList) {
      this.loading = true

      const formData = this.prepareDataToSave()

      const id = this.form.id_usuario

      await updateCompanyUser(id, formData)
        .then(() => {
          if (redirectToList) {
            this.openModalSucesso()
          } else {
            this.savedAlert = !this.savedAlert
          }

          this.saving = false
          this.savingAndNew = false
          this.cleanForm()
        }).catch(error => {
          this.handleError(error.response)

          this.saving = false
          this.savingAndNew = false
        })

      this.loading = false
    },
    prepareDataToSave() {
      return {
        nome: this.form.nome,
        cpf: this.formatCpf(this.form.cpf),
        dataNascimento: this.form.data_nascimento,
        sexo: this.form.sexo,
        nomeMae: this.form.nome_mae,
        idNacionalidade: this.form.id_nacionalidade,
        email: this.form.email,
        empresasId: this.form.empresa,
      }
    },

    handleError(response) {
      const errors = response.status === 400 || response.status === 404

      if (errors) {
        warningInfoMessage(
          response.data.errors,
          'Confira os dados e depois salve o usuário.',
          'Entendido',
        )

        return
      }

      warningMessage('Não foi possível realizar a sua solicitação.<br /> Entre em contato com o suporte.')
    },

    cleanForm() {
      this.form.cpf = ''
      this.form.nome = ''
      this.form.sexo = ''
      this.form.data_nascimento = ''
      this.input.data_nascimento = ''
      this.form.categoria = ''
      this.form.nome_mae = "";
      this.form.id_nacionalidade = null;

      this.form.errorApi.cpf = ''
      this.form.errorApi.nome = ''
      this.form.errorApi.sexo = ''
      this.form.errorApi.data_nascimento = ''
      this.form.errorApi.categoria = ''
      this.form.errorApi.nome_mae = "";
      this.form.errorApi.id_nacionalidade = "";

      this.$refs.colaboradorRules.reset()
    },

    preenchimentoFormulario(data) {
      this.form.id_pessoa_fisica = data.id_pessoa_fisica
      this.form.cpf = data.cpf
      this.form.nome = data.nome
      this.form.sexo = data.sexo
      this.form.data_nascimento = data.data_nascimento
      this.form.email = data.email
      this.form.empresa = getArrayAttr(data.empresa, 'id_empresa')
      this.form.nome_mae = data.nome_mae;
      this.form.id_nacionalidade = data.id_nacionalidade;

      if (this.$route.params.empresa) {
        this.empresa = this.$route.params.empresa
        this.linkItems[1].name = this.empresa.nome
      } else {
        this.redirectToList()
      }
      this.setNascimentoPicker()
      this.populated = !this.populated;
    },
    setNascimentoPicker() {
      this.input.data_nascimento = moment(this.form.data_nascimento).format('DD/MM/YYYY')
    },
    setNascimentoInput() {
      this.form.data_nascimento = moment(this.input.data_nascimento, 'DD/MM/YYYY').format('YYYY-MM-DD')
    },

    redirectToList() {
      this.$router.push({ name: 'empresa-usuario-list' })
    },
    openModalConfirm() {
      this.modal.showModal = true
    },
    closeModalConfirm() {
      this.modal.showModal = false
    },
    openModalAction() {
      this.modalAction.showModal = true
    },
    closeModalAction() {
      this.modalAction.showModal = false
    },
    openModalError() {
      this.modalError.showModal = true
    },
    openModalSucesso() {
      this.modalSucesso.showModal = true
    },
    formatCpf(cpf) {
      return cpf.replace(/\D/g, '')
    },
    resolveRoute(route) {
      if (route != this.$route.name) {
        this.$router.push(
          {
            name: route,
            params: {
              empresa: this.empresa,
            },
          },
        )
      }
    },
    desvincularColaborador() {
      const parameters = {
        id_pessoa_fisica: this.form.id_pessoa_fisica,
        id_empresa: this.empresa.id_empresa,
        ano: this.form.ano,
      }
      this.$http.patch(this.$api.colaborador(), parameters)
        .then(() => {
          this.redirectToList()
        })
    },
    updateNacionalidade(id_nacionalidade) {
      this.form.id_nacionalidade = id_nacionalidade;
    },
  },
}
</script>
