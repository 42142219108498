var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('overlay',{attrs:{"show":_vm.loading,"class-name":"content-wrapper"}},[_c('PageHeader',{attrs:{"screen-name":'Editar '+ _vm.form.nome,"link-items":_vm.linkItems}}),_c('ConfirmModal',{attrs:{"item-identifier":"usuário","modal-show":_vm.modal.showModal,"type-modal":_vm.modal.typeModal},on:{"confirmed":_vm.redirectToList,"canceled":_vm.closeModalConfirm}}),_c('ActionColaboradorModal',{attrs:{"item-identifier":"usuário","modal-show":_vm.modalAction.showModal,"type-modal":_vm.modalAction.typeModal},on:{"confirmed":_vm.desvincularColaborador,"canceled":_vm.closeModalAction}}),_c('SucessoModal',{attrs:{"modal-show":_vm.modalSucesso.showModal,"type-modal":_vm.modalSucesso.typeModal,"case-modal":_vm.modalSucesso.caseModal},on:{"confirmed":_vm.redirectToList}}),_c('ErrorModal',{attrs:{"modal-show":_vm.modalError.showModal,"type-modal":_vm.modalError.typeModal,"case-modal":_vm.modalError.caseModal},on:{"confirmed":_vm.redirectToList}}),_c('main',{staticClass:"card"},[_c('HeaderEmpresa',{attrs:{"empresa":_vm.empresa},on:{"confirmed":_vm.resolveRoute}}),_c('section',{staticClass:"form p-2 mt-2"},[_c('validation-observer',{ref:"colaboradorRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"lg":"4","md":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":"CPF*"}},[_c('validation-provider',{attrs:{"name":"CPF","rules":"required|cpf"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('###.###.###-##'),expression:"'###.###.###-##'"}],attrs:{"id":"cpf","placeholder":"000.000.000-00","autocomplete":"off"},model:{value:(_vm.form.cpf),callback:function ($$v) {_vm.$set(_vm.form, "cpf", $$v)},expression:"form.cpf"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4","md":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Nome completo*"}},[_c('validation-provider',{attrs:{"name":"nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nome-input","state":errors.length > 0 || _vm.form.errorApi.nome || _vm.form.errorApi.error ? false:null,"placeholder":"John Doe","disabled":!_vm.editavel},model:{value:(_vm.form.nome),callback:function ($$v) {_vm.$set(_vm.form, "nome", $$v)},expression:"form.nome"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.form.errorApi.nome)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errorApi.nome)+" ")]):_vm._e(),(_vm.form.errorApi.error)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errorApi.error)+" ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4","md":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Nome da mãe"}},[_c('validation-provider',{attrs:{"name":"nome_mae"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 || _vm.form.errorApi.nome_mae
                        ? false
                        : null,"id":"nome_mae-input","disabled":!_vm.editavel,"placeholder":"Nome da mãe"},model:{value:(_vm.form.nome_mae),callback:function ($$v) {_vm.$set(_vm.form, "nome_mae", $$v)},expression:"form.nome_mae"}}),(_vm.form.errorApi.nome_mae)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errorApi.nome_mae)+" ")]):_vm._e(),(_vm.form.errorApi.error)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errorApi.error)+" ")]):_vm._e()]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"lg":"4","md":"4","sm":"12"}},[_c('validation-provider',{attrs:{"name":"sexo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Sexo*","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"id":"sexo","options":_vm.generoSelect,"label":"topico","placeholder":"Selecione uma opção","clearable":true},model:{value:(_vm.form.sexo),callback:function ($$v) {_vm.$set(_vm.form, "sexo", $$v)},expression:"form.sexo"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e()],1)]}}])})],1),_c('b-col',{attrs:{"lg":"4","md":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Data de nascimento*"}},[_c('validation-provider',{attrs:{"name":"data_nascimento"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-input-group',[_c('vue-cleave',{staticClass:"form-control",attrs:{"id":"data-nascimento-input","raw":false,"options":_vm.option.data,"placeholder":"00/00/0000","name":"data_nascimento","autocomplete":"off","disabled":!_vm.editavel},on:{"input":_vm.setNascimentoInput},model:{value:(_vm.input.data_nascimento),callback:function ($$v) {_vm.$set(_vm.input, "data_nascimento", $$v)},expression:"input.data_nascimento"}}),_c('b-input-group-append',[_c('b-form-datepicker',{staticClass:"form-control d-flex align-items-center",attrs:{"locale":"pt-BR","button-only":"","right":"","hide-header":true,"max":_vm.today,"label-help":"Selecione a data de nascimento","button-variant":"custom-blue","clearable":"","disabled":!_vm.editavel},on:{"input":_vm.setNascimentoPicker},model:{value:(_vm.form.data_nascimento),callback:function ($$v) {_vm.$set(_vm.form, "data_nascimento", $$v)},expression:"form.data_nascimento"}})],1)],1),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.form.errorApi.data_nascimento)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errorApi.data_nascimento)+" ")]):_vm._e(),(_vm.form.errorApi.error)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errorApi.error)+" ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4","md":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":"E-mail*"}},[_c('validation-provider',{attrs:{"name":"E-mail","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","placeholder":"johndoe@gmail.com","autocomplete":"off","type":"email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4","md":"4","sm":"12"}},[(_vm.populated)?_c('NacionalidadeField',{attrs:{"idNacionalidade":_vm.form.id_nacionalidade,"errorApiIdNacionalidade":_vm.form.errorApi.id_nacionalidade,"customClass":"edit-company-user-colaborador"},on:{"updateNacionalidade":_vm.updateNacionalidade}}):_vm._e()],1)],1)],1)],1)],1),_c('section',{staticClass:"buttons p-2"},[_c('b-button',{staticClass:"mr-2 mb-2 mb-sm-0",attrs:{"id":"save-user","variant":"custom-blue","disabled":_vm.saving},on:{"click":function($event){$event.preventDefault();return _vm.validationForm(true)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"CheckIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Salvar usuário")]),(_vm.saving)?_c('span',{staticClass:"spinner-border spinner-border-sm ml-1",attrs:{"role":"status","aria-hidden":"true"}}):_vm._e()],1),_c('b-button',{attrs:{"id":"cancel-register","disabled":_vm.saving,"variant":"outline-danger"},on:{"click":function($event){$event.preventDefault();return _vm.openModalConfirm.apply(null, arguments)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"XIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Cancelar")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }